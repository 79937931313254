import React from 'react'
import Header from './Header'

import Demo from "./Navbar"


const Navbar = () => {
  

    return (
        <>
        <Header/>
        <Demo   />
        </>
   
    )
}

export default Navbar